import { ITags } from "../interface/main.interface";
import { IMusicData } from "../interface/music.interface";

// 플레이리스트, leftNavPage
export const playlists: ITags = {
  Total: "음악 전체",
  Cyworld: "싸이월드 감성",
  Feeling: "감성 & 느낌",
  Tensionup: "텐션 올려!!",
  Groove: "그루브 좀 타볼까~",
  Working: "노동요~",
  Favorite: "최근 꽂힌 음악!!",
  Rainy: "비오는 날..",
  Nolyrics: "가사가 없는?!",
  Before2024: "Before 2024 Pick",
  Pick2024: "2024 Pick",
};

// playlist에 내용 넣는 용
enum pl {
  Cyworld = "Cyworld",
  Feeling = "Feeling",
  Tensionup = "Tensionup",
  Groove = "Groove",
  Working = "Working",
  Favorite = "Favorite",
  Rainy = "Rainy",
  Nolyrics = "Nolyrics",
  Before2024 = "Before2024",
  Pick2024 = "Pick2024",
}

// 유튜브 음악 데이터
export const musicData: IMusicData[] = [
  // {
  //   url: "",
  //   artist: "",
  //   title: "",
  //   playlists: [pl.Pick2024],
  // },
  {
    url: "https://youtu.be/rb0pYTAAEqM?si=rTMyqLJE4kXfvJmR",
    artist: "태연",
    title: "Fine",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/k_nLGVxmrIE?si=mRMUhGjiJ6RY1317",
    artist: "Miss $",
    title: "니가 아니었기를",
    playlists: [pl.Pick2024, pl.Feeling],
  },
  {
    url: "https://youtu.be/oeLHy3dIiXs?si=RIHDOguUkYFJGRWb",
    artist: "이병우",
    title: "돌이킬 수 없는 걸음(장화홍련 OST)",
    playlists: [pl.Pick2024, pl.Feeling, pl.Nolyrics],
  },
  {
    url: "https://youtu.be/nP-GQGNvfbw?si=X3zk2NwHJAa7-yOL",
    artist: "존박",
    title: "Falling",
    playlists: [pl.Pick2024, pl.Feeling],
  },
  {
    url: "https://youtu.be/A8V0-gLxeB0?si=1NBS-iFZm42kZdob",
    artist: "Dr. Dre",
    title: "The Next Episode (feat. Snoop Dogg, Nate Dogg & Kurupt)",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/cLMLxnTvy3M?si=qxz0OGdkAJg1wcWm",
    artist: "BoyWithUke",
    title: "Migraine",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/B1tFWy8-mpg?si=s3lTUqNrBpbpLLyv",
    artist: "cut ver",
    title: "AUTOMATIC REMIX",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/cv1naUa3_3g?si=Do_buiCWeQQf6oF3",
    artist: "Kanye West",
    title: "Runaway",
    playlists: [pl.Pick2024, pl.Working, pl.Feeling],
  },
  {
    url: "https://youtu.be/4YKi6-vhAcw?si=cQBy0c72IqwQDNJT",
    artist: "에릭남 & 웬디",
    title: "봄인가봐",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/Zo9h51YIFsM?si=ve9e4_L23Zj1pjzh",
    artist: "Loving Caliber",
    title: "18",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/dR7dda5gHl0?si=k1_mtG1UPpMmYTVa",
    artist: "Mattias Tell",
    title: "Trippin' All over You",
    playlists: [pl.Pick2024, pl.Working, pl.Favorite],
  },
  {
    url: "https://youtu.be/8KeFJIx64eQ?si=1JwcGQlz8koCDlJK",
    artist: "Otis Kane",
    title: "Free",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/LSaQJFaYnBA?si=lj-t7zInUY5Lo2jH",
    artist: "BoyWithUke",
    title: "Ghost",
    playlists: [pl.Pick2024, pl.Rainy, pl.Feeling],
  },
  {
    url: "https://youtu.be/MNTarTRNA4o?si=f6VkR1TO3aQsjOdY",
    artist: "BoyWithUke",
    title: "Toxic",
    playlists: [pl.Pick2024, pl.Working, pl.Rainy],
  },
  {
    url: "https://youtu.be/3pb0tXvhBhs?si=PCew0xdaTzE0dRFQ",
    artist: "위아더나잇",
    title: "티라미수케익",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/SZvhmoQlSJc?si=IV-AKHzCa_es0gpf",
    artist: "엔플라잉",
    title: "Blue Moon",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/8yXGI3kcLsw?si=t9ieL4DCYiaj-Zfn",
    artist: "NewJeans",
    title: "Supernatural",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/cO--M-DaMP0?si=Ll8uHqSERxXNvGyG",
    artist: "BABYMONSTER",
    title: "LIKE THAT",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/_82e2JwdQJU?si=m1c0VIw5RVStoQzC",
    artist: "황린(슈퍼팬드2)",
    title: "It's Rainning",
    playlists: [pl.Pick2024, pl.Working, pl.Tensionup],
  },
  {
    url: "https://youtu.be/B-AqBu0D0q8?si=kVlj1LWxc5gvABSk",
    artist: "비스트",
    title: "비가 오는 날엔",
    playlists: [pl.Pick2024, pl.Rainy],
  },
  {
    url: "https://youtu.be/TBXQu8ORnBQ?si=4_xqwQy_iJIS220V",
    artist: "Charlie Puth",
    title: "Dangerously",
    playlists: [pl.Pick2024, pl.Feeling],
  },
  {
    url: "https://youtu.be/2MEQ0UGoh6I?si=Lkh2ROFYm7w-frxc",
    artist: "민지",
    title: "무희(Odoriko)",
    playlists: [pl.Pick2024, pl.Working, pl.Feeling, pl.Rainy, pl.Favorite],
  },
  {
    url: "https://youtu.be/ZbZSe6N_BXs?si=tuhNI_7CIIPs1hQA",
    artist: "Pharrell Williams",
    title: "Happy",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/h--P8HzYZ74?si=tt-rMjsaoZoGHc7S",
    artist: "Zedd & Alessia Cara",
    title: "Stay",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/B1Vv1zQyR04?si=0fQMRz8pyUHTp-Hw",
    artist: "NewJeans",
    title: "Right Now'",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/GV_HP7yoIyc?si=zeVODq4hAthJVlr_",
    artist: "NewJeans",
    title: "Bubble Gum",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/vrM2mrI83uk?si=NskG3o-Vnh8RJYq7",
    artist: "NewJeans",
    title: "How Sweet",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/nFigEiB9P5c?si=bKbH6P9tWw8FVgLO",
    artist: "NewJeans",
    title: "ASAP",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/SXM1q0CTfew?si=WPYNOm_RAo2tfY0d",
    artist: "NewJeans",
    title: "Get Up",
    playlists: [pl.Pick2024, pl.Feeling, pl.Rainy],
  },
  {
    url: "https://youtu.be/-jEmhjZr8RE?si=JSpgf8QY_VunuCjK",
    artist: "NewJeans",
    title: "Cool With You",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/XCkpalH6Ppk?si=Hn_PAXWv4H7Bgvht",
    artist: "NewJeans",
    title: "ETA",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/n7ePZLn9_lQ?si=wXC5wSDSCgJct3BO",
    artist: "NewJeans",
    title: "Super Shy",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/Krr2u8BUtLw?si=VGPaxdfpF-cS6aHO",
    artist: "NewJeans",
    title: "NewJeans",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/2Kff0U8w-aU?si=OwW9gdj1zkDr-9zL",
    artist: "NewJeans",
    title: "OMG",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/V6TEcoNUmc8?si=8aJnzU3McA8rZO0d",
    artist: "NewJeans",
    title: "Ditto",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/8QWtOgBJZNc?si=1SzmJc4nDDolViDu",
    artist: "NewJeans",
    title: "Hurt",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/0M4QMVQJRsw?si=DueRfFDq1FiltHZZ",
    artist: "NewJeans",
    title: "Cookie",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/ghrlZIMDzbM?si=guZJbGg288HqK3bS",
    artist: "NewJeans",
    title: "Hype Boy",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/kdOS94IjzzE?si=0XFOy4qWm1-Jl3EZ",
    artist: "NewJeans",
    title: "Attention",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/lxbQKemBaRE?si=LNvLURivE3q0PPCV",
    artist: "Doja Cat",
    title: "Like That (feat. Gucci Mane)",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/Myz6pdW07O0?si=bHmYyuiFjTTx6yZi",
    artist: "태연",
    title: "To. X",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/n6J4_VvAbI0?si=WO0NGtTNdIbkb6ua",
    artist: "Will Jay",
    title: "Off The Record",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/dm0ndgjk9V4?si=h7-eHVdOXlH9B1Jt",
    artist: "Britney Spears",
    title: "Toxic",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/jthP1K1T9Ys?si=kAS6lim4TvWbF25z",
    artist: "Britney Spears",
    title: "Baby One More Time",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/hryQUXYfkX0?si=HKBiFQtRGiRFNgsX",
    artist: "The Vamps",
    title: "Just My Type",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/RQ9BWndKEgs?si=PnzCSfYWVRsAybn_",
    artist: "Beyoncé",
    title: "Deja Vu (feat. Jay-Z)",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/Wzkxs7v0uuk?si=wBxLiSFpDLIhUN-w",
    artist: "Jean Tonique",
    title: "Lit Up (feat. DiRTY RADiO)",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/bkoEIpHApzA?si=NJgq7aN84pKYj99D",
    artist: "이선희",
    title: "인연",
    playlists: [pl.Pick2024, pl.Feeling, pl.Rainy],
  },
  {
    url: "https://youtu.be/CTQd71zkItA?si=lyw3H2ZPhjUVMbNv",
    artist: "Mad Soul Child",
    title: "Dear",
    playlists: [pl.Pick2024, pl.Feeling],
  },
  {
    url: "https://youtu.be/HpAqkHvu_FE?si=Q2IhgJPJPsa6pCib",
    artist: "폴킴",
    title: "좋아해요",
    playlists: [pl.Pick2024, pl.Feeling],
  },
  {
    url: "https://youtu.be/ucpb4BYNJ6s?si=3t87DwhxZ2aOwqz2",
    artist: "부석순",
    title: "자꾸만 웃게 돼",
    playlists: [pl.Pick2024, pl.Feeling, pl.Favorite, pl.Working],
  },
  {
    url: "https://youtu.be/1w7OgIMMRc4?si=-9n50xjaBH2U1_Nx",
    artist: "Guns N' Roses",
    title: "Sweet Child O' Mine",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/p3KQH3px0Mg?si=G-l_l_VACOrpaeKf",
    artist: "타샤니",
    title: "경고",
    playlists: [pl.Pick2024, pl.Before2024, pl.Working],
  },
  {
    url: "https://youtu.be/QgD4alRSRSM?si=VLdPAfk_R3V1P4xO",
    artist: "The Score",
    title: "Enemies",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://youtu.be/KprKbo4tLjo?si=7uR4o0Yib0g0ilrH",
    artist: "G-Eazy & Halsey",
    title: "Him & I",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=EALA9YBeGNI&ab_channel=AdelynPaik-Topic",
    artist: "Adelyn Paik",
    title: "Apricity",
    playlists: [pl.Pick2024, pl.Feeling, pl.Rainy],
  },
  {
    url: "https://youtu.be/5Rrdt9KcZgk?si=qlw6dZXmOLluzn8I",
    artist: "Linkin Park",
    title: "New divide",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=hgaZhV5KtHg",
    artist: "American Authors",
    title: "I'm Born To Run",
    playlists: [pl.Pick2024, pl.Tensionup, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=v-F-bB6c5aU",
    artist: "박완규",
    title: "Alone (카우보이 비밥 ost)",
    playlists: [pl.Pick2024, pl.Feeling, pl.Rainy],
  },
  {
    url: "https://www.youtube.com/watch?v=4y-wlb3F_Zg",
    artist: "Tom Frane",
    title: "Don't Leave (feat. RJ Pasin)",
    playlists: [pl.Pick2024, pl.Working, pl.Feeling],
  },
  {
    url: "https://www.youtube.com/watch?v=BWW-ZOaf040",
    artist: "Merk & Kremont",
    title: "Sad Story (Out Of Luck)",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=GWNODbG9AIM",
    artist: "Justin Bieber",
    title: "Peaches (feat. Daniel Caesar & Giveon)",
    playlists: [pl.Pick2024, pl.Groove, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=vMGyTUGH9jg&ab_channel=Gummy-Topic",
    artist: "Gummy & Ailee",
    title: "질투나",
    playlists: [pl.Before2024, pl.Feeling],
  },
  {
    url: "https://www.youtube.com/watch?v=l5t9IXtTr6g",
    artist: "Radiohead",
    title: "Creep",
    playlists: [pl.Pick2024, pl.Feeling],
  },
  {
    url: "https://www.youtube.com/watch?v=6sUIP6gSats",
    artist: "메이플스토리 샤레니안의 기사 BGM",
    title: "My Prince My Kingdom",
    playlists: [pl.Pick2024, pl.Feeling, pl.Rainy, pl.Favorite],
  },
  {
    url: "https://www.youtube.com/watch?v=MhHxVaJ9sT4",
    artist: "테일즈위버 OST",
    title: "Second Run",
    playlists: [pl.Before2024, pl.Nolyrics],
  },
  {
    url: "https://www.youtube.com/watch?v=mlFDY5TMgeg",
    artist: "테일즈위버 OST",
    title: "Reminiscence",
    playlists: [pl.Before2024, pl.Nolyrics],
  },
  {
    url: "https://www.youtube.com/watch?v=L2LB12IxLDU",
    artist: "DJ OKAWARI",
    title: "Speed of Light (feat. 二宮愛)",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=UjLnvXpkq68",
    artist: "DJ OKAWARI",
    title: "Perfect Blue",
    playlists: [pl.Pick2024, pl.Nolyrics, pl.Feeling],
  },
  {
    url: "https://www.youtube.com/watch?v=zQ2LfW-DWDc",
    artist: "DJ OKAWARI",
    title: "Everything's Alright (feat. Kaori Sawada)",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=7maJOI3QMu0",
    artist: "이루마",
    title: "River Flows in You",
    playlists: [pl.Pick2024, pl.Nolyrics, pl.Rainy, pl.Feeling],
  },
  {
    url: "https://www.youtube.com/watch?v=z8zHpZOZodQ",
    artist: "Joe Hisaishi",
    title: "Summer",
    playlists: [pl.Pick2024, pl.Nolyrics],
  },
  {
    url: "https://www.youtube.com/watch?v=AULG4MoYxQk",
    artist: "DJ Okawari",
    title: "Flower Dance",
    playlists: [pl.Pick2024, pl.Nolyrics, pl.Feeling, pl.Working, pl.Rainy],
  },
  {
    url: "https://www.youtube.com/watch?v=KuliCkN2oic",
    artist: "Panic! At The Disco",
    title: "House of Memories",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=6C33PKdV710",
    artist: "5SOS",
    title: "Teeth",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=CXNv4_wQjKQ",
    artist: "5SOS",
    title: "Youngblood",
    playlists: [pl.Pick2024, pl.Working, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=YxQNuxJ5LBw",
    artist: "Bishop Briggs",
    title: "CHAMPION",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=IEOda2KAu0E",
    artist: "3OH!3",
    title: "We Are Young",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=TO-_3tck2tg",
    artist: "Imagine Dragons",
    title: "Bones",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=w-JH4Ty_F3A",
    artist: "Boys Like Girls",
    title: "The Great Escape",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=RNx5f0snpK8",
    artist: "Ava Max",
    title: "Kings & Queens",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=wH3JmLBOnMU",
    artist: "Stellar",
    title: "Ashes",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=pvrkfb1C4tE",
    artist: "Imagine Dragons",
    title: "Demons",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=0I647GU3Jsc",
    artist: "Imagine Dragons",
    title: "Natural",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=jecQcgbyetw",
    artist: "Legend",
    title: "The Score",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=dEnA5Du7d9M",
    artist: "All Time Low",
    title: "Time-Bomb",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=W0DM5lcj6mw",
    artist: "Imagine Dragons",
    title: "Believer",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=oPA0z4W-kcU",
    artist: "Fall Out Boy",
    title: "Centuries",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=Hn0F8M0iiW0",
    artist: "Alexander 23",
    title: "Girl ",
    playlists: [pl.Pick2024, pl.Working, pl.Groove],
  },
  {
    url: "https://www.youtube.com/watch?v=OkOohmpromQ",
    artist: "NIKI",
    title: "Selene",
    playlists: [pl.Pick2024, pl.Working, pl.Groove],
  },
  {
    url: "https://www.youtube.com/watch?v=d5jTRIOahWQ",
    artist: "Tommy Newport",
    title: "On Top",
    playlists: [pl.Pick2024, pl.Working, pl.Groove],
  },
  {
    url: "https://www.youtube.com/watch?v=sG5bCSLDF8M",
    artist: "The Internet",
    title: "Under Control",
    playlists: [pl.Pick2024, pl.Working, pl.Groove],
  },
  {
    url: "https://www.youtube.com/watch?v=SlKYLHAMLr0",
    artist: "TRADE L",
    title: "NG (feat. pH-1 & Jay Park)",
    playlists: [pl.Pick2024, pl.Working, pl.Groove],
  },
  {
    url: "https://www.youtube.com/watch?v=sxjpGEDH5Ks",
    artist: "Alessia Cara",
    title: "Fishbowl",
    playlists: [pl.Pick2024, pl.Working, pl.Groove],
  },
  {
    url: "https://www.youtube.com/watch?v=pYHjJJuwfpY",
    artist: "leejean",
    title: "secondary option",
    playlists: [pl.Pick2024, pl.Working, pl.Groove],
  },
  {
    url: "https://www.youtube.com/watch?v=hFTs6HbtxbE&ab_channel=%EC%9B%85%ED%82%A4",
    artist: "QWER",
    title: "고민중독",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=-kcZNuFYnns&ab_channel=QWER-Topic",
    artist: "QWER",
    title: "Discord",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=kWmcsY7WmpU&ab_channel=%EC%9B%85%ED%82%A4",
    artist: "(여자)아이들",
    title: "나는 아픈 건 딱 질색이니까",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://youtu.be/zHb5ls32Rvc?si=boNJksOcBqIHtCb3",
    artist: "Livingston",
    title: "Architect",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=wUiWIBiVH4U&ab_channel=TrailerMusicWorldI",
    artist: "Extreme Music",
    title: "Bring Me Back To Life",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=M-YvkPtneYE&ab_channel=AdamLambert-Topic",
    artist: "Adam Lambert",
    title: "Runnin'",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=JmrLnNYrATw&ab_channel=VOIL%C3%80-Topic",
    artist: "VOILÀ",
    title: "Therapy",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=iBDW7yO82is&ab_channel=TheEverlove-Topic",
    artist: "The Everlove",
    title: "I'm Dangerous",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=_PBlykN4KIY&ab_channel=TheScoreVEVO",
    artist: "The Score",
    title: "Unstoppable",
    playlists: [pl.Pick2024, pl.Tensionup, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=EKVZrbitbd8&ab_channel=SamTinnesz",
    artist: "Sam Tinnesz",
    title: "Legends Are Made",
    playlists: [pl.Pick2024, pl.Tensionup, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=tSQ6CyyPatM&ab_channel=SmashIntoPieces",
    artist: "Smash Into Pieces",
    title: "All Eyes On You",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=nimfRXQS9P4&ab_channel=SeafretOfficial",
    artist: "Seafret",
    title: "Be My Queen",
    playlists: [pl.Pick2024, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=Du3bg-zDL1Q&ab_channel=PositionMusic",
    artist: "Oh The Larceny",
    title: "Real Good Feeling",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=7YAAyUFL1GQ&ab_channel=FallOutBoyVEVO",
    artist: "Fall Out Boy",
    title: "The Last Of The Real Ones",
    playlists: [pl.Pick2024, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=16YnOUnbE6s&ab_channel=ArizonaZervasVEVO",
    artist: "Arizona Zervas",
    title: "ROXANNE",
    playlists: [pl.Pick2024, pl.Groove, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=eZaHN_ix4Zw&ab_channel=Games",
    artist: "The Americanos",
    title:
      "In My Foreign (feat. Ty Dollah $ign & Nick jam & Lil Yachty & French Montana)",
    playlists: [pl.Pick2024, pl.Groove, pl.Working, pl.Favorite],
  },
  {
    url: "https://www.youtube.com/watch?v=yn2Ek2zUXBs&ab_channel=SEL",
    artist: "Laura Roy & DAUL",
    title: "Water (DAUL Remix)",
    playlists: [pl.Pick2024, pl.Groove, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=k756Z_5JurI&ab_channel=JXS_BPOfficial",
    artist: "크러쉬",
    title: "흠칫",
    playlists: [pl.Groove, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=ORz6cOa-Lfs&ab_channel=ChrisBrownVEVO",
    artist: "Chris Brown",
    title: "Fine China",
    playlists: [pl.Pick2024, pl.Groove, pl.Working, pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=4EcNa_cOr4o&ab_channel=KISSOFLIFE-Topic",
    artist: "KISS OF LIFE",
    title: "Sugarcoat (NATTY Solo)",
    playlists: [pl.Pick2024, pl.Groove, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=fnU81MtpcJI&ab_channel=Agenda-Topic",
    artist: "Agenda",
    title: "Important Things (feat.Choilb)",
    playlists: [pl.Pick2024, pl.Groove, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=9FIbRKyZamY&ab_channel=MaryJBlige",
    artist: "Mary J. Blige",
    title: "Need Love (feat. Usher)",
    playlists: [pl.Pick2024, pl.Groove, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=wLrVX2GIrNg&ab_channel=2xxx%21-Topic",
    artist: "2xxx",
    title: "Piss On Me (feat. DEAN & punchnello)",
    playlists: [pl.Pick2024, pl.Groove, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=NuB-1myGido",
    artist: "LOL",
    title: "Rise fea. The Glitch Mob & Mako & The Word Alive",
    playlists: [pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=3dm_5qWWDV8",
    artist: "Muse",
    title: "Hysteria",
    playlists: [pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=oJDsJ4z-YAQ",
    artist: "Fall Out Boy",
    title: "The phoenix",
    playlists: [pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=YJVmu6yttiw",
    artist: "Skrillex",
    title: "Bangarang (feat. Sirah)",
    playlists: [pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=TBD3WRO7Iy8",
    artist: "bbno$",
    title: "Rich Brian",
    playlists: [pl.Pick2024, pl.Tensionup, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=HIgvP7B3Hg8",
    artist: "Bruno Mars",
    title: "Runaway Baby",
    playlists: [pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=vqkB7AKERz8",
    artist: "NMIX",
    title: "DASH",
    playlists: [pl.Working, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=jf0_fwHYAbE",
    artist: "청하",
    title: "I'm Ready",
    playlists: [pl.Working, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=K7J_0DDw2RU",
    artist: "SG워너비",
    title: "죄와 벌",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=MfZWh2bYYeE",
    artist: "가비엔제이",
    title: "Happiness",
    playlists: [pl.Before2024],
  },
  {
    url: "https://youtu.be/-NkEGzMyAqo?si=wYWCHztwfhSB0E1m",
    artist: "먼데이 키즈",
    title: "Bye Bye Bye",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=VO88htcm09E",
    artist: "SG워너비",
    title: "광",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=dJRkhdWsu_M",
    artist: "긱스",
    title: "Officially Missing You",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=jjuB0S2460Q",
    artist: "버즈",
    title: "남자를 몰라",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=-kq2oHzgjLc",
    artist: "Brown Eyed Girls",
    title: "다가와서",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=aIC-b2SrH7U",
    artist: "임정희",
    title:
      "사랑에 미치면 (feat. 아웃캐스트 빅보이 & J.Y. Park ″The Asian Soul″)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=XgbrZZB9Ya0",
    artist: "씨야",
    title: "사랑하기 때문에",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=_MythyZ0w3s",
    artist: "씨야",
    title: "미친 사랑의 노래",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=_zwA5jlgfmo",
    artist: "SG워너비",
    title: "한여름날의 꿈 (feat. 옥주현)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=_-7ophEQBN4",
    artist: "먼데이 키즈",
    title: "가슴으로 외쳐 (feat. Rhymer)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=BEK9vOGAqXM",
    artist: "먼데이 키즈",
    title: "발자국",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=xmX5lqVBH1s",
    artist: "BIG Naughty",
    title: "Vancouver 2",
    playlists: [pl.Feeling, pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=0bTCIbyvBBc",
    artist: "NELL",
    title: "기억을 걷는 시간",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=6HLr5ghMG1c",
    artist: "제아 & 미료",
    title: "Love Is...",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=Na7voCrpzF0",
    artist: "프라이머리",
    title: "씨시루 (feat. Zion.T & Gaeko)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=hQQyYLkqIIk",
    artist: "린",
    title: "오늘 밤 (feat. 배치기)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=nUxLhI8iOrc",
    artist: "긱스",
    title: "어때 (feat. 하림)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=xKZ3U8viuIU",
    artist: "아이유",
    title: "금요일에 만나요 (feat. 장이정)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=l0rcWimJmQ8",
    artist: "하이포 & 아이유",
    title: "봄 사랑 벚꽃 말고",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=6yzwnte9rXc",
    artist: "Zion.T",
    title: "꺼내 먹어요",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=PTIIlOr_C48",
    artist: "다비치",
    title: "두사랑 (feat. 매드클라운)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=IoqZ3tjbu40",
    artist: "M.C THE MAX",
    title: "그 남잔 말야",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=8diBGaW4Mh4",
    artist: "태양",
    title: "눈, 코, 입",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=cQuqs2LrXbo",
    artist: "백예린",
    title: "우주를 건너",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=Y4U1V-eCe5s",
    artist: "BTS",
    title: "봄날",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=ixkBzA94y0M",
    artist: "박재범",
    title: "All I Wanna Do (feat. Hoody & Loco)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=PjrvwPPY-O8",
    artist: "소나기",
    title: "용준형 (feat. 10cm)",
    playlists: [pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=uw_HR9jIJww",
    artist: "헤이즈",
    title: "Jenga (feat. Gaeko)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=7eOmBC9sUPM",
    artist: "펀치",
    title: "밤이 되니까",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=7vEe8dLW7WM",
    artist: "펀치 & 김보경",
    title: "지금 술 한잔 했어",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=m-xmTB12EqI",
    artist: "펀치 & 더원",
    title: "아프다니까",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=AmdgIK8-raM",
    artist: "티아라",
    title: "TTL (Time To Love)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=H04mbKV44so",
    artist: "우원재",
    title: "시차 (feat. 로꼬 & 그레이)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=BUzI-awsi1s",
    artist: "장범준",
    title: "노래방에서",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=VpaUh_BGqE0",
    artist: "엔플라잉",
    title: "옥탑방",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=eN4QuOaXUTs",
    artist: "이소라",
    title: "신청곡",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=-t2BFc8QIrA",
    artist: "개리 & 정인",
    title: "사람냄새",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=dYIT_jeUBKg",
    artist: "프리스타일",
    title: "Y",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://youtu.be/wecpRs9p00E?si=xI6_8kTXvnwsOcZi",
    artist: "델리 스파이스",
    title: "챠우챠우",
    playlists: [pl.Pick2024, pl.Cyworld, pl.Feeling, pl.Rainy],
  },
  {
    url: "https://youtu.be/BYyVDi8BpZw?si=N1zEeRP_EbmeEkCr",
    artist: "델리 스파이스",
    title: "고백",
    playlists: [pl.Pick2024, pl.Cyworld, pl.Feeling, pl.Rainy],
  },
  {
    url: "https://www.youtube.com/watch?v=IH728Tc9AX4",
    artist: "박명호",
    title: "울고 있는 너의 모습 보면 (feat. 류주환)",
    playlists: [pl.Cyworld, pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=9FeqfatBxF8",
    artist: "박명호",
    title: "사진 (하늘만 바라봐) (feat. 류주환 & Same Lee)",
    playlists: [pl.Cyworld, pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=np42odaM8jw",
    artist: "드렁큰 타이거",
    title: "너희가 힙합을 아느냐?",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=1q_t6RNuH8c",
    artist: "폴킴",
    title: "모든 날, 모든 순간",
    playlists: [pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=g7R8YphsB2Y",
    artist: "KozyPop",
    title: "파란색",
    playlists: [pl.Feeling, pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=VPo5vrntXZs",
    artist: "싸이",
    title: "아름다운 이별 2 (feat. 이재훈)",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=IIMk6enNTmk",
    artist: "홍대광",
    title: "답이 없었어",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=A4dbtFe6FsM",
    artist: "언터쳐블",
    title: "베인 (feat. Koonta of Rude Paper)",
    playlists: [pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=G9zL-A78oRg",
    artist: "프라이머리",
    title: "아끼지마 (feat. 초아 & 아이언)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=Xq8-TO_YQdI",
    artist: "신승훈 & 에일리",
    title: "Fly Away",
    playlists: [pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=eHir_vB1RUI",
    artist: "태연",
    title: "Rain",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=lWDbSm4ExCw",
    artist: "NELL",
    title: "청춘연가",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },

  {
    url: "https://www.youtube.com/watch?v=mhnuvWRKLSo",
    artist: "먼데이 키즈",
    title: "흩어져",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=qut_xT4unKs",
    artist: "먼데이 키즈",
    title: "입맞춤",
    playlists: [pl.Cyworld, pl.Feeling, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=VRblIA3U_Y4",
    artist: "먼데이 키즈",
    title: "신기루",
    playlists: [pl.Cyworld, pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=s1e3O1Cm5Gs",
    artist: "먼데이 키즈",
    title: "차가운 커피",
    playlists: [pl.Cyworld, pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=j1twcJsU7Ww",
    artist: "먼데이 키즈",
    title: "고장난 열차",
    playlists: [pl.Cyworld, pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=g3rD2V4meGs",
    artist: "이영지",
    title: "낮 밤",
    playlists: [pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=5eFYzh_YjLU",
    artist: "Supreme Team",
    title: "나만 모르게 (feat. T)",
    playlists: [pl.Cyworld, pl.Feeling, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=5CMBNIAki88",
    artist: "이현우",
    title: "헤어진 다음날",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=Ua-vVBfopHA",
    artist: "김동률",
    title: "기억의 습작",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=_G1cVDy0fTg",
    artist: "Duke & Jones & Louis Theroux",
    title: "Jiggle Jiggle",
    playlists: [pl.Feeling, pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=2lODuVyCcgU",
    artist: "에이트",
    title: "잘가요 내사랑",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=4I5AQcafqhA",
    artist: "플라워",
    title: "Crying",
    playlists: [pl.Cyworld, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=NcWPtW2lNoE",
    artist: "플라워",
    title: "For You",
    playlists: [pl.Cyworld, pl.Feeling, pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=3fp9effzCTE",
    artist: "버즈",
    title: "1st",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=-xvOv7yL10I",
    artist: "버즈",
    title: "Monologue",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=eN99TCIdIt4",
    artist: "버즈",
    title: "약자의 눈물",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=G4BuSPt9iNQ",
    artist: "SG 워너비",
    title: "Timeless",
    playlists: [pl.Cyworld, pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=Qpf26PtBXgo",
    artist: "소녀시대",
    title: "FOREVER 1",
    playlists: [pl.Before2024],
  },
  {
    url: "https://youtu.be/_FHFv4HL4TY?si=Q3XXg0a_haREMbad",
    artist: "우디",
    title: "이 노래가 클럽에서 나온다면",
    playlists: [pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=ZgKExhAL7mE",
    artist: "라붐",
    title: "체온",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=F01sUq_Rtds",
    artist: "YB",
    title: "박하사탕",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=_tN91WXp5Rw",
    artist: "이수영",
    title: "얼마나 좋을까 (FINAL FANTASY-X OST)",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=kXYiU_JCYtU",
    artist: "Linkin Park",
    title: "Numb",
    playlists: [pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=8Irb9NEZkRM",
    artist: "10CM & BIG Naughty",
    title: "딱 10CM만",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=lvwSKGIYoag",
    artist: "Brown Eyes",
    title: "점점",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=ok1782_PBaA",
    artist: "얀",
    title: "그래서 그대는",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=jpMoPG5W860",
    artist: "홍경민",
    title: "후",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=1Mb5b9J7xa0",
    artist: "조PD",
    title: "My Style",
    playlists: [pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=4oKDKl4IOM8",
    artist: "조PD",
    title: "Fever (feat. 이정현)",
    playlists: [pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=w7CBx-pSUbk",
    artist: "조PD",
    title: "보란듯이 (feat. 정슬기)",
    playlists: [pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=yfx8APtBmAU",
    artist: "MC 스나이퍼",
    title: "야간비행 (feat. Rachael Yamagata)",
    playlists: [
      pl.Feeling,
      pl.Working,
      pl.Rainy,
      pl.Groove,
      pl.Before2024,
      pl.Pick2024,
    ],
  },
  {
    url: "https://www.youtube.com/watch?v=2A-PEGmBC48",
    artist: "MC 스나이퍼",
    title: "사랑비극 Part.1 (feat. 김신의)",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=4yKNsJWXwTI",
    artist: "그루비룸",
    title: "행성 (feat. Blue.D & Jhnovr)",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=Hrb9jpU_IdU",
    artist: "첸 & 헤이즈 & 바이브",
    title: "썸타",
    playlists: [pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=qNZ3A39m9c8",
    artist: "신용재",
    title: "가수가 된 이유",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=X8mRqGRJeDw",
    artist: "동방신기",
    title: "주문",
    playlists: [pl.Tensionup, pl.Working, pl.Before2024, pl.Pick2024],
  },

  {
    url: "https://www.youtube.com/watch?v=IBuyFRtW2sg",
    artist: "요조 & 김진표",
    title: "좋아해",
    playlists: [pl.Feeling, pl.Working, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=Vo8Gh7IJjm4",
    artist: "김진표",
    title: "아직 못다한 이야기 (feat. BMK)",
    playlists: [pl.Feeling, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=J_f-QJJuZ-U",
    artist: "김진표",
    title: "샴푸의 요정 (feat. 김조한 & 이준)",
    playlists: [pl.Feeling, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=FDYgubT8iCI",
    artist: "김진표",
    title: "아무 말도 하지마 (feat. 케이윌)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=Wp4do3WlQkk",
    artist: "김진표",
    title: "시간을 찾아서 (feat. 이적)",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=OXICsmyfVos",
    artist: "김진표",
    title: "영원토록 (feat. 존박)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=VuknJzeqXZM",
    artist: "태양",
    title: "I Need a Girl",
    playlists: [pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=Wl84Xsbu5cI",
    artist: "김건모",
    title: "미안해요",
    playlists: [pl.Cyworld, pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=615swCopnhs",
    artist: "거미",
    title: "날 그만 잊어요",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=K3Bq9mIHbzw",
    artist: "거미",
    title: "친구라도 될 걸 그랬어",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=wpywf5nZYLU",
    artist: "거미",
    title: "그대 돌아오면",
    playlists: [pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=eNlOJ97LLPs",
    artist: "거미",
    title: "어른아이",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=Tgmet1-9B-A",
    artist: "거미",
    title: "미안해요 (feat. T.O.P)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=9k8FNMPax50",
    artist: "거미",
    title: "Because of You",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=7drhVxWUDLA",
    artist: "거미",
    title: "남자라서",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=o0kIUQlqOec",
    artist: "거미",
    title: "사랑은 없다",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=9SsKw53K8Gg",
    artist: "거미",
    title: "내 생각날 거야",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=B5ngr-9yAJc",
    artist: "거미",
    title: "거울을 보다가",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=0LcN5gIE_TA",
    artist: "거미",
    title: "그만 헤어져",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=TKuArLs00bU",
    artist: "거미",
    title: "헤어진 다음 날 (feat. P-Type)",
    playlists: [pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=biy2PJBZuYI",
    artist: "거미",
    title: "누구세요",
    playlists: [pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=OUQ2wAJZ5aY",
    artist: "거미 & 바비킴",
    title: "러브 레시피",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=BmcP7x99LDk",
    artist: "플라워",
    title: "Endless",
    playlists: [pl.Cyworld, pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=I7EwLNhPgpg",
    artist: "헤이즈",
    title: "돌아오지마 (feat. 용준형)",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=rCeM57e2BfU",
    artist: "헤이즈",
    title: "And July (feat. DEAN & DJ Friz)",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=eelfrHtmk68",
    artist: "Dean",
    title: "D",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=kEuc2w2HVhY",
    artist: "다이나믹 듀오",
    title: "Ring My Bell (feat. 나얼)",
    playlists: [pl.Tensionup, pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=UEbPNjL22gE",
    artist: "다이나믹 듀오",
    title: "불면증 (feat. Bobby Kim)",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=2lwNO6YTADA",
    artist: "다이나믹 듀오",
    title: "날개뼈 (feat. 효린)",
    playlists: [pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=YjcU-0YEr54",
    artist: "윤미래",
    title: "Get It In (feat. Tiger JK & Jung In)",
    playlists: [pl.Tensionup, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=XNQCyR8_2EQ",
    artist: "윤미래 (with JK, Bizzy)",
    title: "Angel",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=kv88gSRnt-8",
    artist: "NELL",
    title: "백야",
    playlists: [pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=NwkNj56IaAw",
    artist: "NELL",
    title: "Ocean of Light",
    playlists: [pl.Feeling, pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=XO92ZWK3j-s",
    artist: "NELL",
    title: "Stay",
    playlists: [pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=70WsITQrB24",
    artist: "NELL",
    title: "믿어선 안될 말",
    playlists: [pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=dNRwPsIBPLI",
    artist: "조장혁",
    title: "중독된 사랑",
    playlists: [pl.Cyworld, pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=2pN87QdvMaM",
    artist: "이적",
    title: "하늘을 달리다",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=1zTZtz_EeU0",
    artist: "이적",
    title: "그땐 미처 알지 못했지",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=WzRpXpkHv00",
    artist: "서태지",
    title: "울트라맨이야",
    playlists: [pl.Working],
  },
  {
    url: "https://youtu.be/U89YuK4SD9E?si=4EIvMsAgcAILdU2r",
    artist: "Brown Eyes",
    title: "벌써 일년",
    playlists: [pl.Cyworld, pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=_2zMo-Rhk54",
    artist: "Brown Eyes",
    title: "With Coffee",
    playlists: [pl.Cyworld, pl.Feeling, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=cJ7KKa6sj4E",
    artist: "노을",
    title: "붙잡고도",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=tKv1E7ITA3c",
    artist: "빅마마",
    title: "Break Away",
    playlists: [pl.Cyworld, pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=HwNxxRBobFE",
    artist: "YB",
    title: "잊을께",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=BY4sHNaV4WU",
    artist: "박효신",
    title: "눈의 꽃",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=RNEAR251EKo",
    artist: "M.C THE MAX",
    title: "사랑의 시",
    playlists: [pl.Cyworld, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=nKFVcpurV54",
    artist: "M.C THE MAX",
    title: "그대는 눈물겹다",
    playlists: [pl.Cyworld, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=CvaB_UaBHR0",
    artist: "이수영",
    title: "휠릴리",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=1ZITc5a3isE",
    artist: "주석",
    title: "정상을 향한 독주 2",
    playlists: [pl.Cyworld, pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=upA01bvUemQ",
    artist: "나얼",
    title: "귀로",
    playlists: [pl.Cyworld, pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=1-Lm2LUR8Ss",
    artist: "버즈",
    title: "가시",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=G7eLG4Tnc9c",
    artist: "버즈",
    title: "겁쟁이",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=OnO92YGNwD8",
    artist: "BMK",
    title: "꽃피는 봄이 오면",
    playlists: [pl.Cyworld, pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=KeMbLY7ztDw",
    artist: "윤도현",
    title: "사랑했나봐",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=djp8SviCHGY",
    artist: "김건모",
    title: "서울의 달",
    playlists: [pl.Cyworld, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=QK-Z1K67uaA",
    artist: "Sting",
    title: "Shape of My Heart",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=aevPTbhFrT8",
    artist: "언터쳐블",
    title: "Tell My Why (feat. Hwayoung)",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=MjHDcR-gkJE",
    artist: "드림캐쳐",
    title: "Full Moon",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=I5_BQAtwHws",
    artist: "드림캐쳐",
    title: "YOU AND I",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=nqC4uQKAa9A",
    artist: "Supreme Team",
    title: "그땐 그땐 그땐",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=CMFRR5n_yyc",
    artist: "Supreme Team",
    title: "왜",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=pLDXOebjblk",
    artist: "Supreme Team",
    title: "너 때문이야 (feat. 소울맨)",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=DLtd1SFCO6c",
    artist: "머생",
    title: "동그랗고 하얀 (feat. 송석준)",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=lPe0RlZ06RE",
    artist: "Due (feat. Miri)",
    title: "Hotclip",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=SpO83duj5wQ",
    artist: "Supreme Team",
    title: "배가 불렀지 (feat. 개코)",
    playlists: [pl.Cyworld, pl.Feeling, pl.Rainy, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=qmkodlvhCSE",
    artist: "O.O.O",
    title: "눈이 마주쳤을 때",
    playlists: [pl.Feeling, pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=qD1kP_nJU3o",
    artist: "IVE",
    title: "WAVE",
    playlists: [pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=ukg1n_12BjM",
    artist: "업타운",
    title: "My Style",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=NcgEXwjAImo",
    artist: "Kim Petras",
    title: "Coconuts ",
    playlists: [pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=aNuwWB0SHUs",
    artist: "Stereo Hearts",
    title: "Glee Cast",
    playlists: [pl.Feeling, pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=AM7rVkwiUIM",
    artist: "Young Cocoa",
    title: "Manila",
    playlists: [pl.Feeling, pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=Ec7TN_11az8",
    artist: "The Kid LAROI & Justin Bieber",
    title: "Stay",
    playlists: [pl.Feeling, pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=_h0hyT5Dkb8",
    artist: "Dead Man Walking",
    title: "City Wolf",
    playlists: [pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=KmVSwFd3TCA",
    artist: "Bazzi",
    title: "Myself",
    playlists: [pl.Feeling, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=vcnfwop3PNc",
    artist: "DEAN",
    title: "21",
    playlists: [pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=uHaQrwrEXvE",
    artist: "COIN",
    title: "Brad Pitt ",
    playlists: [pl.Feeling, pl.Working, pl.Before2024, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=2akWL5BjykA",
    artist: "Brown Eyed Girls",
    title: "이별편지",
    playlists: [pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=qhiZMJ_BYJI",
    artist: "SG워너비",
    title: "멋지게 이별",
    playlists: [pl.Cyworld, pl.Working],
  },
  {
    url: "https://www.youtube.com/watch?v=EzQsoZYY470",
    artist: "JIDA",
    title: "Autumn Breeze (feat. Rachel Lim)",
    playlists: [pl.Feeling, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=tg6DGMhsj7Y",
    artist: "Taio Cruz",
    title: "She's Like A Star",
    playlists: [pl.Feeling, pl.Before2024, pl.Working, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=crB9uNfyEMc",
    artist: "라임버스",
    title: "How Do You Want It (feat. 임정희)",
    playlists: [pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=LB3Lfs_bGqU",
    artist: "Brown Eyes",
    title: "언제나 그랬죠",
    playlists: [pl.Cyworld, pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=ICCgV4ZZEhE",
    artist: "박재범",
    title: "Nothin On You",
    playlists: [pl.Working, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=gBGvfnegBkM",
    artist: "정키 & 거미",
    title: "Without You",
    playlists: [pl.Feeling, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=6rj3h-cEqgg",
    artist: "소유 & 백현",
    title: "비가와",
    playlists: [pl.Feeling, pl.Rainy],
  },
  {
    url: "https://www.youtube.com/watch?v=mnpQsM-tqQU",
    artist: "윤하",
    title: "시간의 지평선",
    playlists: [pl.Working, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=A67RYFTImTQ",
    artist: "IVE",
    title: "Off The Record",
    playlists: [pl.Feeling, pl.Working, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=_pYY8p1gpmc",
    artist: "Barrie Gledden",
    title: "I Am The One",
    playlists: [pl.Tensionup],
  },
  {
    url: "https://www.youtube.com/watch?v=_UkrDNugpXI",
    artist: "요아리",
    title: "TRUE",
    playlists: [pl.Feeling, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=ZlJNkflJjA4",
    artist: "IVE",
    title: "Mine",
    playlists: [pl.Feeling, pl.Working, pl.Pick2024, pl.Favorite],
  },
  {
    url: "https://www.youtube.com/watch?v=sVbpvsrtTzM",
    artist: "크리스포터 & 청하",
    title: "When I Get Old",
    playlists: [pl.Feeling, pl.Working, pl.Pick2024],
  },
  {
    url: "https://www.youtube.com/watch?v=NIPtyAKxlRs",
    artist: "에픽하이",
    title: "우산 (feat. 윤하)",
    playlists: [pl.Cyworld, pl.Rainy],
  },
  {
    url: "https://www.youtube.com/watch?v=xpHJnmp3Lyw",
    artist: "Gavy NJ",
    title: "해바라기",
    playlists: [pl.Cyworld],
  },

  {
    url: "https://www.youtube.com/watch?v=eOqSUto9Z1g",
    artist: "Sweetbox",
    title: "Life Is Cool",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=POApmDGm8r8",
    artist: "MC 스나이퍼",
    title: "마법의 성",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=ppjRIEgcXIY",
    artist: "다이나믹 듀오",
    title: "죽일놈",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=_TOu_1zVCUk",
    artist: "리쌍",
    title: "헤어지지 못하는 여자, 떠나가지 못하는 남자 (feat. 정인)",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=uNgg_uBYZkw",
    artist: "Brown Eyed Girls",
    title: "LOVE",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=VUhGP9sqKdw",
    artist: "원투",
    title: "못된여자 (feat. 서인영)",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=z5_HPxLJBf0",
    artist: "원투",
    title: "못된여자2 (feat. 서인영)",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=bv1PxDNQ6bo",
    artist: "허밍 어반 스테레오",
    title: "Hawaiian Couple",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=faPQoNUC4GQ",
    artist: "자두",
    title: "대화가 필요해",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=v3SaQZypZ_U",
    artist: "하울, 제이",
    title: "사랑인가요 (궁 OST)",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=4mDOG3PAjao",
    artist: "샵",
    title: "내 입술... 따뜻한 커피처럼",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=iaDgfQ0sIYY",
    artist: "싸이",
    title: "낙원 (feat. 이재훈)",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=Cbg6nJulpEQ",
    artist: "이지라이프",
    title: "너말고 니언니",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=oF7C6GuMD5g",
    artist: "Brown Eyed Girls",
    title: "Hold The Line (feat. 조PD)",
    playlists: [pl.Cyworld, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=c9sEdAo39gk",
    artist: "언터쳐블",
    title: "가슴에 살아 (feat. 나르샤)",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=c7KXtINADRo",
    artist: "박해경",
    title: "Lemon Tree",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=sGD75Kncck8",
    artist: "PK헤만",
    title: "Evergreen (feat. 이지혜)",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=fuCmW_1_wxs",
    artist: "더 넛츠",
    title: "사랑의 바보",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=98Q2p-3kMDI",
    artist: "izi",
    title: "응급실",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=fuFJqIfnB5o",
    artist: "버즈",
    title: "사랑은 가슴이 시킨다",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=2lODuVyCcgU",
    artist: "에이트",
    title: "잘가요 내사랑",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=zgvI5Jqph08",
    artist: "양정승",
    title: "밤하늘의 별을 (with KCM, No Noo)",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=h0cj_A0np-M",
    artist: "버즈",
    title: "은인",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=_6C5-abCx6g",
    artist: "윤하",
    title: "기다리다",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=-VP7fUqTWYY",
    artist: "태연",
    title: "들리나요",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=2WyOq7IMEnc",
    artist: "이보람",
    title: "처음 그 자리에",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=oo0ESiTp6VM",
    artist: "유미",
    title: "별",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=qmlUuTvKUpU",
    artist: "러브홀릭",
    title: "인형의 꿈",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=acdAWdnSKmE",
    artist: "럼블피쉬",
    title: "비와 당신",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=1RvuFAWlkdo",
    artist: "god",
    title: "길",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=yPqI1VAHX8Q",
    artist: "모세",
    title: "사랑인걸",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=JRF7F2E96uw",
    artist: "김형중",
    title: "그랬나봐",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=oQjzTVCIK5A",
    artist: "쿨",
    title: "아로하",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=0lgNwO6eUpY",
    artist: "Brown Eyed Soul",
    title: "My Story",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=G6pMhSdv8Fg",
    artist: "테이",
    title: "같은 배게...",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=nD1p_H3qo_A",
    artist: "성시경",
    title: "희재",
    playlists: [pl.Cyworld, pl.Rainy, pl.Before2024],
  },
  {
    url: "https://www.youtube.com/watch?v=gt7kce8hgSs",
    artist: "지아",
    title: "술 한잔 해요",
    playlists: [pl.Cyworld],
  },
  {
    url: "https://www.youtube.com/watch?v=QeHrryN0zHo",
    artist: "테이",
    title: "사랑은...향기를 남기고",
    playlists: [pl.Cyworld],
  },
];
